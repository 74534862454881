<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-table
        topBorderClass="topcolor-lightblue"
        ref="grid1"
        title="평가항목별 감사결과"
        tableId="grid1"
        :columnSetting="false"
        :usePaging="false"
        :filtering="false"
        :hideBottom="true"
        :columns="grid1.columns"
        :data="grid1.data"
        :merge="grid1.merge"
        :gridHeight="gridheight"
      >
      </c-table>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'hazop-plan-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        selfInspectionResultId: '',
        selfInspectionStatusCd: '',
        sysRevision: '',
      }),
    },
  },
  data() {
    return {
      gridheight: '',
      grid1: {
        merge: [
          { index: 0, colName: 'itemMstName' },
          { index: 1, colName: 'itemTypeName' },
        ],
        columns: [
          {
            name: 'itemMstName',
            field: 'itemMstName',
            label: '평가항목',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'itemTypeName',
            field: 'itemTypeName',
            label: '구분',
            align: 'left',
            style: 'width: 200px',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            label: 'No.',
            align: 'center',
            style: 'width: 60px',
            sortable: false,
          },
          {
            name: 'itemName',
            field: 'itemName',
            label: '세부항목',
            align: 'left',
            type: 'html',
            sortable: false,
          },
          {
            name: 'selfInspectionResultName',
            field: 'selfInspectionResultName',
            label: '평가',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
          {
            name: 'selfInspectionRemark',
            field: 'selfInspectionRemark',
            label: '평가 근거',
            align: 'left',
            type: 'html',
            style: 'width: 350px',
            sortable: false,
          },
          {
            name: 'answerUserName',
            field: 'answerUserName',
            label: '답변자',
            align: 'center',
            style: 'width: 100px',
            sortable: false,
          },
        ],
        data: [],
      },
      list1Url: '',
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.gridheight = (window.innerHeight - 208) + 'px';
      this.editable = this.$route.meta.editable;
      this.list1Url = selectConfig.sop.pca.item.revs.all;

      this.getList();
    },
    getList() {
      this.$http.url = this.list1Url;
      this.$http.type = 'GET';
      this.$http.param = {sysRevision: this.popupParam.sysRevision, selfInspectionResultId: this.popupParam.selfInspectionResultId};
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
      },);
    },
  }
};
</script>
